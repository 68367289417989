
























































import { KlevuEvents } from '@klevu/core';
import type { PropType } from '@nuxtjs/composition-api';
import {
  computed,
  defineComponent,
  useContext,
  useFetch
} from '@nuxtjs/composition-api';

import type { ProductInterface } from '~/composables';
import { validateFraction } from '~/diptyqueTheme/helpers/priceFormatter';
import { useMegaMenuMobileStore } from '~/diptyqueTheme/stores/megaMenuMobile';
import { useAddToCart } from '~/helpers/cart/addToCart';
import type { CategoryInterface } from '~/modules/catalog/category/types';
import productGetters from '~/modules/catalog/product/getters/productGetters';
import type { Product } from '~/modules/catalog/product/types';
import useCart from '~/modules/core/composables/useCart';

import { useQuickSearch } from '../../index';

export default defineComponent({
  name: 'VaimoCrossSellProduct',
  components: {
    VaimoBanner: () => import('atoms/VaimoBanner.vue')
  },
  props: {
    product: {
      required: true,
      type: Object as PropType<Product | ProductInterface>
    },
    withLoadCart: Boolean
  },
  setup(props) {
    const { addItemToCart, loading } = useAddToCart();
    const { app } = useContext();
    const { load, cart } = useCart();
    const quickSearchStore = useQuickSearch();
    const mobileStore = useMegaMenuMobileStore();
    const addProductToCart = (product) => {
      addItemToCart(product);
      //add tracking data related to clicked product
      KlevuEvents.searchProductClick(
        product.product,
        quickSearchStore.searchTerm
      );
    };

    const getProductQuantity = computed(() => {
      return cart.value.items?.find(
        (item) => item.product.uid === props.product.uid
      )?.quantity;
    });

    const getDynamicPriceText = (price) => {
      return app.i18n.t('From {price}', {
        price
      });
    };

    const getProductPrice = (product) => {
      return product.price_type === 'DYNAMIC'
        ? getDynamicPriceText(
            validateFraction(product.custom_set_minimal_price)
          )
        : validateFraction(productGetters.getPrice(product).regular);
    };

    const goToProduct = async (product) => {
      //add tracking data related to clicked product
      KlevuEvents.searchProductClick(product, quickSearchStore.searchTerm);

      /* Added one second for smooth transition  */
      setTimeout(() => {
        mobileStore.hide();
      }, 1000);
    };

    const productLink = computed(() => {
      return `${app.localePath(
        productGetters.getSlug(
          props.product as Product,
          props.product?.categories?.[0] as CategoryInterface
        )
      )}`;
    });

    let modelsQuantity = props.product?.revamp_related_products?.length - 1;

    if (modelsQuantity < 1) {
      modelsQuantity = 0;
    }

    const getModelsQuantity = computed(() => modelsQuantity || 0);

    useFetch(() => {
      if (!cart.value?.items?.length && props.withLoadCart) {
        load();
      }
    });

    return {
      addProductToCart,
      getProductPrice,
      getModelsQuantity,
      getProductQuantity,
      goToProduct,
      loading,
      productGetters,
      validateFraction,
      productLink
    };
  }
});
