import { defineStore } from 'pinia';
import { KlevuSearchSorting } from '@klevu/core';
import { lsGet, lsSet } from '~/diptyqueTheme/composable/useLocalStorage';

const useSearch = defineStore('search-store', {
  state: () => {
    return {
      searchTerm: lsGet('search_term') ?? '',
      products: [],
      cmsPages: [],
      categories: [],
      options: [],
      sliders: [],
      similarProducts: [],
      sort: KlevuSearchSorting.Relevance,
      searchFilterExcludes: [
        'articleNumberManufacturer',
        'articleNumberMax',
        'matrixId',
        'baseId',
        'commonSize',
        'isOnStock',
        'inventory_item_id',
        'rim_size',
        'type',
        'tags'
      ],
      showMore: false,
      loading: true,
      filtersOpen: false,
      managerOptionToggleFn: null,
      managerUpdateSlideFn: null,
      searchFn: null,
      itemsPerPage: 20,
      page: 1,
      totalResultsFound: 0,
      totalProductsFound: 0,
      totalCmsFound: 0
    };
  },
  actions: {
    setProducts(arr) {
      this.products =
        typeof arr === 'object' && typeof arr.length !== 'undefined' ? arr : []
    },
    setCmsPages(arr) {
      this.cmsPages =
          typeof arr === 'object' && typeof arr.length !== 'undefined' ? arr : []
    },
    setCategories(arr) {
        this.categories =
            typeof arr === 'object' && typeof arr.length !== 'undefined' ? arr : []
    },
    setSimilar(arr) {
      this.similarProducts =
        typeof arr === 'object' && typeof arr.length !== 'undefined' ? arr : []
    },
    setOptions(arr) {
      this.options =
        typeof arr === 'object' && typeof arr.length !== 'undefined' ? arr : []
    },
    setSliders(arr) {
      this.sliders =
        typeof arr === 'object' && typeof arr.length !== 'undefined' ? arr : []
    },
    setSearchTerm(searchTerm) {
      lsSet('search_term', searchTerm);
      this.searchTerm = lsGet('search_term');
    },
    resetSearch() {
      this.setSearchTerm('');
      this.showMore = false;
      this.setProducts([]);
      this.setCmsPages([]);
      this.setCategories([]);
      this.setOptions([]);
      this.setSliders([]);
      this.setSimilar([]);
      this.totalResultsFound = 0;
      this.totalResultsFound = 0;
      this.totalCmsFound = 0;
      this.page = 1;
    },
    toggleFilterSidebar() {
      this.filtersOpen = !this.filtersOpen;
    }
  }
});

export default useSearch;
